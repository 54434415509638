import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the Atrioc-related word in 6 tries. After each guess, the color of
        the tiles will change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="correct"
        />
        <Cell value="T" />
        <Cell value="R" />
        <Cell value="I" />
        <Cell value="O" />
        <Cell value="C" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter A is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="G" />
        <Cell value="L" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="I"
          status="present"
        />
        <Cell value="Z" />
        <Cell value="Z" />
        <Cell value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="W" />
        <Cell value="H" />
        <Cell value="O" />
        <Cell isRevealing={true} isCompleted={true} value="D" status="absent" />
        <Cell value="A" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter D is not in the word in any spot.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        By{' '}
        <a href="https://vrtx.dev" className="underline font-bold">
          Vortex
        </a>
        .
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        Special thanks to{' '}
        <a href="https://discord.gg/atrioc" className="underline font-bold">
          the Atrioc Discord
        </a>
        ,{' '}
        <a
          href="https://www.reddit.com/r/atrioc"
          className="underline font-bold"
        >
          r/atrioc
        </a>
        , and tropicbliss for the help!
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        <a
          href="https://github.com/VortexHero/atriocle/issues/new?template=word-request.md&labels=word"
          className="underline font-bold"
        >
          Request words to be added to the word list!
        </a>
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This is built using an open source version of{' '}
        <a
          href="https://www.nytimes.com/games/wordle/index.html"
          className="underline font-bold"
        >
          Wordle
        </a>{' '}
        - check out{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          the original code here
        </a>{' '}
        and{' '}
        <a
          href="https://github.com/vortexhero/atriocle"
          className="underline font-bold"
        >
          the modified code here
        </a>
        .
      </p>
    </BaseModal>
  )
}
