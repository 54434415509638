export const WORDS = [
  'nvidia',
  'poggies',
  'cow',
  'ghost',
  'btrioc',
  'quadge',
  'ewing',
  'quack',
  'shampoo',
  'boomer',
  'frames',
  'atrio',
  'reddit',
  'asu',
  'darren',
  'coke',
  'burgzy',
  'dweam',
  'jensen',
  'crypto',
  'ads',
  'finger',
  'glocal',
  'coffee',
  'brand',
  'leeches',
  'linkus',
  'lol',
  'streamer',
  'sykik',
  'stanzy',
  'quincy',
  'shanty',
  'ludwig',
  'maya',
  'marketing',
  'atroic',
  'rtx',
  'ari',
  'gummies',
  'nicknames',
  'ninja',
  'a',
  'dwayne',
  'glizzard',
  'negneg',
  'yummers',
  'whale',
  'arizona',
  'yep',
  'grind',
  'mustard',
  'lolw',
  'crinky',
  'monkey',
  'moo',
  'brandon',
  'stream',
  'zoomer',
  'atrioc',
  'enron',
  'paris',
  'clown',
  'blev',
  'cringe',
  'hotdog',
  'monday',
  'atricoin',
  'tarketing',
  'amongus',
  'dream',
  'kid',
  'sekiro',
  'gambit',
  'morbius',
  'market',
  'sheesh',
  'greenout',
  'rocist',
  'dog',
  'lul',
  'biga',
  'evangao',
  'whale',
  'stanz',
  'cronge',
  'blevins',
  'leech',
  'pog',
  'geforce',
  'glizzy',
  'dadtrioc',
  'league',
  'amogus',
  'sigma',
  'old',
  'whodat',
  'hitman',
  'twitch',
  'virgin',
  'bloons',
  'wordle',
  'blippy',
  'lulw',
  'goblin',
  'rock',
]
